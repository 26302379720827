import { FabricImage, StaticCanvas } from 'fabric';
import transactionModel from '@/models/transaction';
import { BASE_BG_ID, BRUSH_COLOR, LOW_QUALITY_MAX_SIZE } from './constants';

export { getImageUrl } from '@/utils';

export function getNameFromPath(path) {
	const parts = path?.split('/');
	return parts?.[parts.length - 1];
}

export function loadImage(url) {
	const img = new Image();
	return new Promise((resolve, reject) => {
		img.onload = () => resolve(img);
		img.onerror = () => reject(new Error(`Failed to load: ${url}`));
		img.src = url;
	});
}

export function getDrawCursor(brushSize = 20, brushColor = BRUSH_COLOR) {
	const circle = `
		<svg
			height="${brushSize}"
			fill="${brushColor}"
			viewBox="0 0 ${brushSize * 2} ${brushSize * 2}"
			width="${brushSize}"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="50%"
				cy="50%"
				r="${brushSize}"
			/>
		</svg>
	`;

	const base64Url = `data:image/svg+xml;base64,${window.btoa(circle)}`;

	return `url(${base64Url}) ${brushSize / 2} ${brushSize / 2}, crosshair`;
}

export function pathToSvg(path, params) {
	const { width, height, backgroundColor, scaleFactor } = params;

	if (scaleFactor) {
		for (const command of path.path) {
			// Start from index 1 because index 0 is the command (e.g., 'M', 'L', 'C')
			for (let i = 1; i < command.length; i++) {
				command[i] *= scaleFactor; // Scale each coordinate point
			}
		}

		// scale the stroke width
		path.strokeWidth *= scaleFactor;
	}

	return `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
		<rect width="100%" height="100%" fill="${backgroundColor}" />
		${path.toSVG()}
	</svg>`;
}

export function canvasToBlob(canvas, type, quality) {
	return new Promise(resolve => {
		canvas.toBlob(resolve, type, quality);
	});
}

export async function svgToPng(svg, width, height, scaleFactor = 1) {
	const canvas = document.createElement('canvas');

	canvas.width = width;
	canvas.height = height;

	const blob = new Blob([svg], { type: 'image/svg+xml' });
	const url = URL.createObjectURL(blob);
	const img = await loadImage(url);

	const ctx = canvas.getContext('2d');
	ctx.scale(scaleFactor, scaleFactor);
	ctx.drawImage(img, 0, 0);
	URL.revokeObjectURL(url);

	const pngBlob = await canvasToBlob(canvas, 'image/png');

	return pngBlob.arrayBuffer();
}

export function getBoundingBox(path, scaleFactor = 1) {
	const minX = Math.floor(path.aCoords.tl.x * scaleFactor);
	const maxX = Math.ceil(path.aCoords.br.x * scaleFactor);
	const minY = Math.floor(path.aCoords.tl.y * scaleFactor);
	const maxY = Math.ceil(path.aCoords.br.y * scaleFactor);

	return [minX, minY, maxX, maxY];
}

export function fabricImageFromURL(url) {
	return FabricImage.fromURL(url, { crossOrigin: 'Anonymous' });
}

export function getTransparencyImage() {
	const blackColor = '#e8e8e8';
	const whiteColor = 'transparent';
	const transparencyImage = `linear-gradient(45deg, ${blackColor} 25%, ${whiteColor} 25%), linear-gradient(-45deg, ${blackColor} 25%, ${whiteColor} 25%), linear-gradient(45deg, ${whiteColor} 75%, ${blackColor} 75%), linear-gradient(-45deg, ${whiteColor} 75%, ${blackColor} 75%)`;

	return transparencyImage;
}

export function extractCanvasImg(canvas) {
	const [img] = canvas.getObjects('image');

	return img;
}

export function getLowImageSize(imageSize) {
	if (!imageSize) {
		return null;
	}

	const { width, height } = imageSize;
	const ratio = width / height;

	if (width > height) {
		const resultWidth = Math.min(width, LOW_QUALITY_MAX_SIZE);
		return { width: resultWidth, height: Math.round(resultWidth / ratio) };
	}

	const resultHeight = Math.min(height, LOW_QUALITY_MAX_SIZE);
	return { width: Math.round(resultHeight * ratio), height: resultHeight };
}

export async function getDataToSave(txId, settings = {}) {
	const {
		customBackgrounds = [],
		background = null,
		backgroundColor = null,
		activeBlur = false,
		blurWidth,
		blur = 0
	} = settings;

	const customBg = customBackgrounds?.find(item => item.id === background);

	let responseId = null;

	if (customBg) {
		const { path } = customBg;

		const res = await fetch(path);
		const bgFile = await res.arrayBuffer();

		const response = await transactionModel.saveBackground(txId, bgFile);

		responseId = response?.id;
	}

	const isBaseBackground =
		background === BASE_BG_ID || (!background && activeBlur);

	responseId ||= background;

	return {
		background: {
			color: isBaseBackground ? null : backgroundColor,
			id: isBaseBackground ? null : responseId,
			blur: activeBlur ? blur : null,
			blurWidth: activeBlur ? blurWidth : null,
			base: isBaseBackground
		}
	};
}

export function getCustomBgPath(path) {
	return transactionModel.backgroundImageUrl(path).href;
}

export function centerCrop(img, container, canvas) {
	canvas ||= container;

	const canvasAspectRatio = container.width / container.height;
	const imgAspectRatio = img.width / img.height;

	let cropWidth;
	let cropHeight;

	if (canvasAspectRatio > imgAspectRatio) {
		cropWidth = img.width;
		cropHeight = img.width / canvasAspectRatio;
	} else {
		cropHeight = img.height;
		cropWidth = img.height * canvasAspectRatio;
	}

	const cropX = (img.width - cropWidth) / 2;
	const cropY = (img.height - cropHeight) / 2;

	img.set({
		left: 0,
		top: 0,
		scaleX: canvas.width / cropWidth,
		scaleY: canvas.height / cropHeight,
		cropX,
		cropY,
		cropWidth,
		cropHeight
	});
}

async function cloneCanvas({
	canvas,
	canvasImg,
	width,
	height,
	backgroundColor,
	canvasDom
}) {
	const clonedCanvas = await canvas.clone();
	const clonedImg = await canvasImg.clone();

	const resultCanvas = new StaticCanvas(canvasDom, {
		width,
		height
	});

	clonedImg.scaleToWidth(width);

	resultCanvas.add(clonedImg);

	if (clonedCanvas?.backgroundImage) {
		clonedCanvas?.backgroundImage.set({
			visible: true,
			opacity: 1
		});

		resultCanvas.backgroundImage = clonedCanvas?.backgroundImage;

		centerCrop(resultCanvas.backgroundImage, clonedImg, resultCanvas);
	}

	resultCanvas.backgroundColor = backgroundColor || null;

	resultCanvas.renderAll();

	return resultCanvas;
}

export function getHQCanvas({ canvas, backgroundColor }) {
	const hqCanvasDom = document.createElement('canvas');

	if (!canvas) {
		return new StaticCanvas(hqCanvasDom);
	}

	const canvasImg = extractCanvasImg(canvas);

	return cloneCanvas({
		canvas,
		canvasImg,
		backgroundColor,
		canvasDom: hqCanvasDom,
		width: canvasImg.width,
		height: canvasImg.height
	});
}

export async function getLQCanvas({ canvas, backgroundColor }) {
	const lqCanvasDom = document.createElement('canvas');

	if (!canvas) {
		return new StaticCanvas(lqCanvasDom);
	}

	const canvasImg = extractCanvasImg(canvas);

	const size = getLowImageSize({
		width: canvasImg.width,
		height: canvasImg.height
	});

	return cloneCanvas({
		canvas,
		canvasImg,
		backgroundColor,
		canvasDom: lqCanvasDom,
		...size
	});
}

export async function fabricImageToBuffer(img, signal) {
	const url = img.toDataURL?.() ?? img;
	const res = await fetch(url, { signal });

	return new Uint8Array(await res.arrayBuffer());
}

export async function bufferToBase64(buffer) {
	const base64url = await new Promise(r => {
		const reader = new FileReader();
		reader.onload = () => r(reader.result);
		reader.readAsDataURL(new Blob([buffer]));
	});

	return base64url;
}

export function bufferToUrl(buffer, type = 'image/png') {
	const blob = new Blob([buffer], { type });
	return URL.createObjectURL(blob);
}
