import { lazy, useEffect, Suspense, useState } from 'react';
import { Dialog, DialogContent, dialogClasses } from '@/components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPrice, showError } from '@/utils';
import {
	Box,
	CircularProgress,
	Divider,
	Stack,
	Typography
} from '@mui/material';
import { useMatch } from 'react-router-dom';
import routes from '@/routes';
import { fetchPrices } from '@/store/billing/thunks';
import stripeModel from '@/models/stripe';
import { usePaymentMethod, useShowCurrencySelector } from '@/hooks';
import { CurrencySelector } from '@/components/CurrencySelector';
import { usePaymentUtils } from '@/components/PaymentElement/hooks';
import { Button } from '@/components/Button';
import { getAmountByCredit, getCheapestPlan, getDiscount } from '@/utils/plans';
import { AUTH_MIN_WIDTH } from '@/components/AuthModal/constants';

const PaymentElement = lazy(() => import('@/components/PaymentElement'));

export function FastCheckout(props) {
	const { t } = useTranslation();
	const fastEditor = !!useMatch(routes.fastCheckout);
	const fastDashboard = !!useMatch(routes.fastCheckoutDashboard);

	const isFastCheckoutRoute = fastEditor || fastDashboard;

	const { currency } = useSelector(state => state.auth);
	const showCurrencySelector = useShowCurrencySelector();
	const { executePayment, paymentError, executingPayment, clearError } =
		usePaymentUtils();

	const { loading, data: prices } = useSelector(
		state => state.billing.fetchPrices
	);
	const paymentMethod = usePaymentMethod();

	const dispatch = useDispatch();

	const [nextAction, setNextAction] = useState(null);
	const [stripeModule, setStripeModule] = useState(null);
	const [showCheckoutButton, setShowCheckoutButton] = useState(true);

	const cheapestPlan = getCheapestPlan(prices);

	const trialPlan = prices?.find(
		price => price.name === 'trial' && !!price.active
	);

	const discount = getDiscount(trialPlan, cheapestPlan);

	const loadingRender = (
		<Box
			sx={{
				minHeight: 130,
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			<CircularProgress />
		</Box>
	);

	function handleClose(_, reason) {
		if (reason !== 'backdropClick') {
			window.location.href = fastDashboard
				? routes.dashboard
				: routes.editor;
		}
	}

	useEffect(() => {
		if (!isFastCheckoutRoute) {
			return;
		}

		(async () => {
			try {
				await dispatch(fetchPrices({ currency })).unwrap();
			} catch (err) {
				showError(err);
			}
		})();
	}, [isFastCheckoutRoute, currency]);

	useEffect(() => {
		if (!trialPlan || nextAction) {
			return;
		}

		(async () => {
			try {
				const response = await stripeModel.getNextAction(trialPlan?.id);

				setNextAction(response);
			} catch (error) {
				showError(error);
			}
		})();
	}, [trialPlan]);

	if (!isFastCheckoutRoute) {
		return null;
	}

	return (
		<Dialog
			open
			onClose={handleClose}
			sx={{
				[`.${dialogClasses.paper}`]: {
					width: '100%',
					pt: 3,
					pb: { xs: 3.5, sm: 6 },
					px: { xs: 1, sm: 3 },
					maxWidth: { xs: 'auto', sm: 520 }
				}
			}}
			maxWidth="xl"
			width="100%"
			minWidth={AUTH_MIN_WIDTH}
			{...props}
		>
			<DialogContent
				sx={{
					px: {
						xs: 1,
						sm: 3
					}
				}}
			>
				{loading && loadingRender}
				{!loading && (
					<Box sx={{ '& form': { pr: 0 } }}>
						<Box mb={3}>
							<Box py={1.25}>
								<Stack
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box>
										<Typography
											variant="body3"
											fontSize={{ xs: 16, sm: 20 }}
											fontWeight={700}
										>
											{t('common.payment')}
										</Typography>
									</Box>

									{showCurrencySelector && (
										<CurrencySelector
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: 'none'
												},
												'& .MuiSelect-iconOutlined': {
													fontSize: 16
												},
												'&& .MuiInputBase-input': {
													fontSize: 12,
													paddingRight: 3.5
												}
											}}
											style={{ minWidth: 45 }}
										/>
									)}
								</Stack>
							</Box>
							<Divider orientation="horizontal" />
							{trialPlan && (
								<>
									<Box py={2}>
										<Stack
											flexDirection="row"
											justifyContent="space-between"
										>
											<Box>
												<Typography variant="body1">
													{t('plans.creditsPlan', {
														quantity: trialPlan.credits ?? 0
													})}
												</Typography>
												<Typography
													variant="body0"
													color="text.secondary"
												>
													{t('plans.priceByCredit', {
														formattedPrice: formatPrice({
															amount:
																getAmountByCredit(trialPlan),
															currency:
																currency || trialPlan.currency,
															fraction: 1
														})
													})}
												</Typography>
											</Box>

											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												{!!discount && (
													<Box
														display="flex"
														alignItems="center"
														justifyContent="center"
														sx={theme => ({
															background:
																theme.palette.text.primary,
															px: 0.5,
															height: 20,
															borderRadius: 1
														})}
													>
														<Typography
															variant="body0"
															fontWeight={700}
															color="background.default"
														>
															{t('common.discount', {
																discount
															})}
														</Typography>
													</Box>
												)}
												<Typography
													variant="body3"
													fontSize={{ xs: 20, sm: 24 }}
													fontWeight={700}
												>
													{formatPrice({
														amount: trialPlan.baseAmount,
														currency:
															currency || trialPlan.currency
													})}
												</Typography>
											</Stack>
										</Stack>
									</Box>
									<Divider orientation="horizontal" />
								</>
							)}
						</Box>
						<Suspense fallback={loadingRender}>
							<PaymentElement
								onSavedPaymentMethod={stripe => {
									if (!paymentMethod.hasPaymentMethod) {
										return executePayment(stripe, {
											priceId: trialPlan.id,
											nextAction
										});
									}
									setShowCheckoutButton(true);
								}}
								onLoadStripeModule={setStripeModule}
								onCancelPaymentMethodChange={() =>
									setShowCheckoutButton(true)
								}
								onOpenSetupIntent={() => {
									clearError();
									setShowCheckoutButton(false);
								}}
								onSetupErr={showError}
								currentPlan={trialPlan}
								finishButtonProps={{
									text: t('common.payNow'),
									fullWidth: true
								}}
								fastCheckout
							/>
							{paymentError && (
								<Typography
									variant="body1"
									color="error.main"
									sx={{ mp: 0 }}
								>
									{paymentError.message || paymentError.data?.message}
								</Typography>
							)}
							{showCheckoutButton && paymentMethod.hasPaymentMethod && (
								<Stack
									direction="row"
									justifyContent="flex-end"
									sx={{ mt: 4 }}
								>
									<Button
										variant="contained"
										fullWidth
										onClick={() =>
											executePayment(stripeModule, {
												priceId: trialPlan.id,
												nextAction
											})
										}
										loading={executingPayment}
									>
										{t('common.payNow')}
									</Button>
								</Stack>
							)}
						</Suspense>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);
}
