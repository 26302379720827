import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';

import {
	addImage,
	addLocalFiles,
	clearLocal,
	restorePositions,
	setBlurWidth,
	setBackgroundColor,
	setBackgroundImg,
	setImagePositions,
	setImageSize,
	setZoom
} from '@/store/editor';
import { Point, Canvas } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '@/routes';
import { removeBackground } from '@/store/editor/thunks';
import { showError, vipsImageFree, debounce } from '@/utils';
import { getErrorParams } from '@/utils/transaction';
import { PLAUSIBLE_EVENTS, sendPlausible } from '@/utils/plausible';
import { showNoCredits } from '@/components/NoCreditsModal/utils';
import transactionModel from '@/models/transaction';
import { useVips } from '@/hooks/vips';

import {
	centerCrop,
	fabricImageToBuffer,
	fabricImageFromURL,
	bufferToUrl,
	extractCanvasImg
} from './utils';

import {
	ALL_CANVAS_WRAPPER_ID,
	BASE_BG_ID,
	CANVAS_WIDTH_MAX,
	CANVAS_WIDTH_MIN,
	CUSTOM_FABRIC_EVENTS,
	DROPZONE_ERRORS,
	MAX_ZOOM,
	MIN_ZOOM
} from './constants';

export function useColors() {
	return [
		'#FFFFFF',
		'#E25241',
		'#D73965',
		'#9037AA',
		'#6041B1',
		'#4154AF',
		'#4696EC',
		'#49A8EE',
		'#53BAD1',
		'#419488',
		'#67AC5C',
		'#98C05C',
		'#FDEA60',
		'#F6C244',
		'#F29C38',
		'#ED6337',
		'#74564A',
		'#9E9E9E',
		'#667D8A',
		'#000000'
	];
}

function getCropOffsets(canvas, canvasImg) {
	return {
		left: (canvasImg.width - canvas.width) / 2,
		top: (canvasImg.height - canvas.height) / 2
	};
}

const getLimits = (canvas, zoom) => {
	const canvasWidth = canvas.getWidth();
	const canvasHeight = canvas.getHeight();

	return {
		minLeft: 0,
		minTop: 0,
		maxLeft: canvasWidth * zoom - canvasWidth,
		maxTop: canvasHeight * zoom - canvasHeight
	};
};

const getCurrentPositions = canvas => {
	return {
		currentLeft: canvas.viewportTransform[4],
		currentTop: canvas.viewportTransform[5]
	};
};

const limitNextPositions = ({ top, left }, { canvas, zoom }) => {
	const limits = getLimits(canvas, zoom);

	return {
		nextLeft: Math.max(-limits.maxLeft, Math.min(limits.minLeft, left)),
		nextTop: Math.max(-limits.maxTop, Math.min(limits.minTop, top))
	};
};

const initialViewportTransform = [1, 0, 0, 1, 0, 0];

const restoreLayerPosition = layer => {
	layer.setViewportTransform(initialViewportTransform);
};

function getImageCursors(zoomLevel = 1) {
	return {
		hoverCursor: zoomLevel > 1 ? 'grab' : 'default',
		moveCursor: zoomLevel > 1 ? 'grabbing' : 'default'
	};
}

function getDataFromUrl(url) {
	const urlSplit = url.split('/');

	const filename = urlSplit[urlSplit.length - 1];
	const ext = filename.split('.').pop();

	return { filename, ext };
}

export function useGetDropError() {
	const { t } = useTranslation();

	return error => {
		const { code } = error || {};

		const dropErrors = {
			[DROPZONE_ERRORS.fileInvalidType]: {
				status: 415,
				data: {
					error: code,
					message: t('errors.imageExtension')
				}
			},
			[DROPZONE_ERRORS.tooManyFiles]: {
				status: 400,
				data: {
					error: code,
					message: t('errors.tooManyFiles')
				}
			},
			[DROPZONE_ERRORS.fileTooLarge]: {
				status: 413,
				data: {
					error: code,
					message: t('errors.imageTooLarge')
				}
			},
			generic: {
				status: 500,
				data: { error: 'generic', message: '' }
			}
		};

		return dropErrors?.[code] || dropErrors.generic;
	};
}

export function useUploadFileFn(redirect = true) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const loggedIn = useSelector(state => state.auth?.loggedIn);

	const getDropError = useGetDropError();

	const showPremiumModal = () => {
		showNoCredits({
			title: t('editor.bulkDisabledModal.title'),
			description: t('editor.bulkDisabledModal.description')
		});
	};

	const handleSingleError = error => {
		setTimeout(() => {
			showError(...getErrorParams(error, t, navigate));
		}, 200);

		if (redirect) {
			navigate(loggedIn ? routes.dashboard : routes.upload);
		}
	};

	const parseSettled = (responseArr = []) => {
		return responseArr.map(localFile => {
			if (localFile.status === 'rejected') {
				return { success: false, ...localFile?.reason };
			}

			return {
				success: true,
				...(localFile?.value || {})
			};
		});
	};

	const parseLocalFile = async file => {
		const hasErrors = file?.errors;
		const parsedFile = !hasErrors ? file : file?.file;

		let fileBlob = parsedFile;
		let { path: filename } = parsedFile;

		if (typeof parsedFile === 'string') {
			const content = await fetch(parsedFile);
			fileBlob = await content.blob();
			filename = getDataFromUrl(parsedFile)?.filename;
		}

		const blobUrl = URL.createObjectURL(fileBlob);

		const data = {
			id: uuid(),
			blob: blobUrl,
			filename,
			fileBlob,
			...(file?.errors ? { errors: file?.errors } : {})
		};

		return hasErrors ? Promise.reject(data) : Promise.resolve(data);
	};

	const getFirstRejectedError = rejectedFiles => {
		const firstRejected = rejectedFiles?.[0] || {};
		return firstRejected?.errors?.[0];
	};

	const bulkUpload = async (files, rejectedFiles) => {
		try {
			sendPlausible(PLAUSIBLE_EVENTS.upload);

			if (!loggedIn && [...files, ...rejectedFiles].length > 1) {
				return showPremiumModal();
			}

			const dropError = getFirstRejectedError(rejectedFiles);

			if (dropError?.code === DROPZONE_ERRORS.tooManyFiles) {
				return showError(dropError?.message);
			}

			let parsedLocalFiles = files.map(item => parseLocalFile(item));

			const localFiles = await Promise.allSettled(parsedLocalFiles);

			parsedLocalFiles = parseSettled(localFiles);

			const successItems = parsedLocalFiles.filter(item => item.success);
			const errorItems = parsedLocalFiles.filter(item => !item.success);

			dispatch(addLocalFiles(successItems));

			for (const errorItem of errorItems) {
				const itemDropError = errorItem?.errors?.[0];

				dispatch(
					addImage({
						id: errorItem.id,
						tempBlob: errorItem.blob,
						error: getDropError(itemDropError)
					})
				);
			}

			if (redirect) {
				navigate(routes.editor);
			}

			const filesUpload = successItems.map(
				async ({ id, fileBlob, blob, filename }) => {
					const file = await fileBlob?.arrayBuffer();

					return dispatch(
						removeBackground({
							localId: id,
							file,
							blobUrl: blob,
							filename
						})
					).unwrap();
				}
			);

			await Promise.all(filesUpload);
		} catch (error) {
			if (redirect) {
				navigate(loggedIn ? routes.dashboard : routes.upload);
			}
		} finally {
			dispatch(clearLocal());
		}
	};

	const uploadFile = async (input, rejectedFiles = []) => {
		try {
			sendPlausible(PLAUSIBLE_EVENTS.upload);

			if (!loggedIn && rejectedFiles.length > 1) {
				return showPremiumModal();
			}

			const dropError =
				input?.errors?.[0] || getFirstRejectedError(rejectedFiles);

			if (dropError?.code === DROPZONE_ERRORS.tooManyFiles) {
				return showError(dropError?.message);
			}

			if (dropError) {
				const errorObj = getDropError(dropError);

				return showError(...getErrorParams(errorObj, t, navigate));
			}

			const { id, blob, filename, fileBlob } = await parseLocalFile(input);

			dispatch(
				addLocalFiles([
					{
						id,
						blob,
						filename
					}
				])
			);

			if (redirect) {
				navigate(routes.editor);
			}

			const file = await fileBlob.arrayBuffer();

			await dispatch(
				removeBackground({
					file,
					blobUrl: blob,
					filename,
					localId: id
				})
			).unwrap();
		} catch (error) {
			handleSingleError(error);
		}
	};

	const getImageBlobFromProxy = async transactionId => {
		try {
			const proxyImage = await transactionModel.getProxyImg(transactionId);
			const imgBlob = await proxyImage.blob();

			return URL.createObjectURL(imgBlob);
		} catch (error) {
			console.warn('Error getting proxy image: ', error);

			return null;
		}
	};

	const urlUploadFile = async imgUrl => {
		try {
			sendPlausible(PLAUSIBLE_EVENTS.upload);

			const transaction = await transactionModel.downloadImgFromUrl(imgUrl);

			const transactionId = transaction?.id;

			const blobUrl = await getImageBlobFromProxy(transactionId);

			if (blobUrl) {
				dispatch(
					addLocalFiles([
						{
							id: transactionId,
							blob: blobUrl
						}
					])
				);

				if (redirect) {
					navigate(routes.editor);
				}
			}

			await dispatch(
				removeBackground({ imgUrl, transactionId, localId: transactionId })
			).unwrap();

			if (!blobUrl && redirect) {
				navigate(routes.editor);
			}
		} catch (error) {
			handleSingleError(error);
		}
	};

	return {
		bulkUpload,
		uploadFile,
		urlUploadFile
	};
}

export function useZoomListener({ canvas, currentImage }) {
	const { settings = {} } = currentImage || {};

	const { zoomLevel = 1 } = settings;

	useEffect(() => {
		if (canvas && zoomLevel) {
			const center = {
				x: (canvas.width * zoomLevel) / 2,
				y: (canvas.height * zoomLevel) / 2
			};

			const { currentLeft, currentTop } = getCurrentPositions(canvas);

			const { nextLeft, nextTop } = limitNextPositions(
				{ top: currentTop, left: currentLeft },
				{ canvas, zoom: zoomLevel }
			);

			canvas.zoomToPoint(new Point(center.x, center.y), zoomLevel);
			canvas.absolutePan({ x: -nextLeft, y: -nextTop });
		}
	}, [zoomLevel, canvas]);
}

export function useBackgroundListeners({
	canvas,
	rendered,
	originalCanvasImg
}) {
	const dispatch = useDispatch();
	const [vipsImage, setVipsImage] = useState({});

	const {
		backgrounds = [],
		images = [],
		selectedImage
	} = useSelector(state => state.editor);

	const { initializeVips, vips, enabled: vipsEnabled } = useVips();

	const currentImage = images.find(item => item.id === selectedImage) || {};

	const { settings = {}, baseBackground = null } = currentImage;

	const {
		background,
		backgroundColor,
		customBackgrounds = [],
		activeBlur,
		blur
	} = settings;

	const removeBackgroundImage = () => {
		canvas.backgroundImage = null;
		canvas.renderAll();
	};

	const removeBackgroundColor = () => {
		canvas.backgroundColor = null;
		canvas.renderAll();
	};

	const preloadBackground = async (bg, signal) => {
		if (!bg) {
			return;
		}

		if (!vips && vipsEnabled) {
			return initializeVips();
		}

		if (!vips) {
			return;
		}

		let { path } =
			[...backgrounds, ...customBackgrounds, baseBackground].find(
				item => item?.id === bg
			) || {};

		path ||= baseBackground?.path || backgrounds?.[0].path;

		try {
			const blob = await fabricImageToBuffer(path, signal);
			if (signal.aborted) {
				return;
			}

			let im = vips.Image.newFromBuffer(blob);
			try {
				const icc = im.getBlob('icc-profile-data');
				if (icc.length) {
					// we need to import ICC profile to avoid .resize
					// creating the image with incorrect colors
					im = im.iccImport();
				}
			} catch {
				// we can ignore safely this message since
				// vips will fail if 'icc-profile-data' is not present
			}

			im.preventAutoDelete();
			setVipsImage({
				path,
				im,
				id: bg,
				alpha: im.hasAlpha()
			});
			dispatch(setBlurWidth(im.width));
		} catch (e) {
			if (e.name === 'AbortError') {
				return;
			}
			// eslint-disable-next-line no-console -- TODO: check error
			console.error(e);
			showError(e, { report: true });
		}
	};

	const addImageBackground = async () => {
		removeBackgroundColor();
		dispatch(setBackgroundColor(null));

		const { path, local } =
			[...backgrounds, ...customBackgrounds, baseBackground].find(
				item => item?.id === background
			) || {};

		if (!path && !activeBlur) {
			return removeBackgroundImage();
		}

		if (vipsEnabled && vipsImage?.id !== background) {
			// vips image is still loading
			return;
		}

		let url;
		let img;
		if (activeBlur && blur && vipsImage?.im) {
			let { im } = vipsImage;

			const resizeRatio = Math.max(
				canvas.width / im.width,
				canvas.height / im.height
			);

			// blur MUST be applied before all other operations
			// since the size of the image affects the blur result
			im = im.gaussblur(blur, { precision: 'approximate' });
			im = im.resize(resizeRatio);

			// Calculate the crop offsets
			const { left, top } = getCropOffsets(canvas, im);
			im = im.crop(left, top, canvas.width, canvas.height);

			// perf: output as .jpg for local background or if no alpha channel, ~50% faster
			const output = vipsImage.alpha && !local ? '.png' : '.jpg';
			const buffer = im.writeToBuffer(output);
			url = bufferToUrl(buffer);

			img = await fabricImageFromURL(url);
		} else {
			// if blur is not set, instead of cropping with vips
			// we can use faster crop with fabric
			img = await fabricImageFromURL(path);
			centerCrop(img, originalCanvasImg, canvas);
		}

		canvas.backgroundImage = img;
		canvas.renderAll();
		canvas.fire(CUSTOM_FABRIC_EVENTS.mainBackgroundAdded);
	};

	const addColorBackground = () => {
		removeBackgroundImage();
		dispatch(setBackgroundImg(null));

		canvas.backgroundColor = backgroundColor || null;
		canvas.renderAll();
		canvas.fire(CUSTOM_FABRIC_EVENTS.mainBackgroundAdded);
	};

	useEffect(() => {
		const im = vipsImage?.im;
		return () => {
			vipsImageFree(im);
			vips?.flushPendingDeletes();
		};
	}, [vipsImage, vips]);

	useEffect(() => {
		if (!vipsEnabled) {
			setVipsImage(null);
		}
	}, [vipsEnabled]);

	useEffect(() => {
		if (canvas && !background && !backgroundColor) {
			removeBackgroundImage();
		}
	}, [background, vips, vipsEnabled, baseBackground]);

	useEffect(() => {
		const abortController = new AbortController();

		if (background === BASE_BG_ID && !baseBackground) {
			return;
		}

		preloadBackground(background, abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [background, vips, vipsEnabled, baseBackground]);

	useEffect(() => {
		if (canvas && !backgroundColor && !background) {
			removeBackgroundColor();
		}
	}, [backgroundColor]);

	useEffect(() => {
		if (canvas && rendered && background && originalCanvasImg) {
			addImageBackground();
		}
	}, [
		blur,
		activeBlur,
		background,
		canvas,
		rendered,
		vipsImage,
		originalCanvasImg
	]);

	useEffect(() => {
		if (canvas && rendered && backgroundColor) {
			addColorBackground();
		}
	}, [backgroundColor, canvas, rendered]);

	useEffect(() => {
		if (activeBlur && !background && baseBackground) {
			dispatch(setBackgroundImg(BASE_BG_ID));
		}
	}, [activeBlur, baseBackground]);
}

export const getCanvasContainerWidth = () => {
	const wrapperRef = document.getElementById(ALL_CANVAS_WRAPPER_ID);

	return wrapperRef?.clientWidth;
};

export function resizeListener({ canvas, canvasReference }) {
	const dispatch = useDispatch();

	function resizeCanvas() {
		if (!canvas) return;

		const baseCanvas = canvasReference || canvas;
		const zoom = canvas.getZoom();

		const [renderedImg] = baseCanvas.getObjects('image');
		const imageWidth = renderedImg?.width;
		const imageHeight = renderedImg?.height;
		const imageRatio = imageHeight / imageWidth;

		const containerWidth = getCanvasContainerWidth();

		const newWidthMin = Math.min(
			imageWidth,
			containerWidth,
			CANVAS_WIDTH_MAX
		);

		const newCanvasWidth = Math.max(newWidthMin, CANVAS_WIDTH_MIN);
		const newCanvasHeight = newCanvasWidth * imageRatio;

		canvas.setDimensions({
			width: newCanvasWidth,
			height: newCanvasHeight
		});

		if (canvas.backgroundImage) {
			const bgResizeRatio = Math.max(
				canvas.width / canvas.backgroundImage.width,
				canvas.height / canvas.backgroundImage.height
			);

			canvas.backgroundImage.set({
				scaleX: bgResizeRatio,
				scaleY: bgResizeRatio
			});
		}

		if (zoom > MIN_ZOOM) {
			dispatch(setZoom(MIN_ZOOM));
		}

		restoreLayerPosition(canvas);
		dispatch(restorePositions());

		if (renderedImg && !canvasReference) {
			renderedImg.scaleToWidth(newCanvasWidth);
			renderedImg.scaleToHeight(newCanvasHeight);
			canvas.centerObject(renderedImg);
			renderedImg.setCoords();
		}

		canvas.renderAll();
	}

	useEffect(() => {
		window.addEventListener('resize', resizeCanvas);

		return () => {
			window.removeEventListener('resize', resizeCanvas);
		};
	}, [canvas, canvasReference]);
}

export function useInitFabricImg({
	type,
	imgUrl,
	canvasRef,
	imgSetSettings = {},
	addedImgCallback = () => {}
}) {
	const initRef = useRef(false);
	const [canvas, setCanvas] = useState(null);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [canvasImg, setCanvasImg] = useState(null);
	const [rendered, setRendered] = useState(false);
	const dispatch = useDispatch();

	resizeListener({ canvas });

	useEffect(() => {
		if (initRef.current) {
			return;
		}

		initRef.current = true;
		setRendered(false);
		const fabricCanvas = new Canvas(canvasRef.current, {
			selection: false,
			fireMiddleClick: true
		});

		if (type) {
			window[type] = fabricCanvas;
		}
		setCanvas(fabricCanvas);
	}, []);

	useEffect(() => {
		if (canvasRef?.current && canvas && imgUrl) {
			(async () => {
				setRendered(false);
				setImageLoaded(false);

				const img = await fabricImageFromURL(imgUrl);

				canvas.clear();

				const containerWidth = getCanvasContainerWidth();

				const maxContainerWidth = Math.min(
					containerWidth,
					CANVAS_WIDTH_MAX
				);

				const canvasWidth = Math.min(img.width, maxContainerWidth);
				const aspectRatio = img.width / img.height;
				const canvasHeight = canvasWidth / aspectRatio;

				const imageWidth = img.width;
				const imageHeight = img.height;

				dispatch(setImageSize({ width: imageWidth, height: imageHeight }));

				const scaleRatioWidth = canvasWidth / imageWidth;
				const scaleRatioHeight = imageHeight / canvasHeight;
				const scaleRatio = Math.min(scaleRatioWidth, scaleRatioHeight);

				canvas.setWidth(canvasWidth);
				canvas.setHeight(canvasHeight);

				const currentZoom = canvas.getZoom();

				img.set({
					selectable: false,
					...getImageCursors(currentZoom),
					hasControls: false,
					scaleX: scaleRatio,
					scaleY: scaleRatio,
					...imgSetSettings
				});

				img.on('added', function () {
					setCanvasImg(img);
					setImageLoaded(true);
				});

				canvas.add(img);
				canvas.renderAll();

				setRendered(true);
				addedImgCallback?.();
			})();
		}
	}, [canvasRef, imgUrl, canvas]);

	return {
		canvas,
		imageLoaded,
		canvasImg,
		rendered
	};
}

export function useAvailableCredits() {
	const { data, loading, success } = useSelector(
		state => state.editor.fetchCredits
	);
	let availableCredits = null;
	let value = null;

	if (success) {
		availableCredits = data.existing - data.used;
		value = !data.existing && !availableCredits ? 0 : availableCredits;
	}

	return { loading, success, value, availableCredits };
}

export function useParseTouchEvents() {
	const touchInitialDistance = useRef(null);
	const touchInitialCoords = useRef(null);

	const clear = () => {
		touchInitialDistance.current = null;
		touchInitialCoords.current = null;
	};

	const parseData = eventParams => {
		const touch1 = eventParams?.e?.touches?.[0];
		const touch2 = eventParams?.e?.touches?.[1];

		const centerX = (touch1.clientX + touch2.clientX) / 2;
		const centerY = (touch1.clientY + touch2.clientY) / 2;

		const initialDistance = touchInitialDistance.current;

		const newDistance = Math.hypot(
			touch1.clientX - touch2.clientX,
			touch1.clientY - touch2.clientY
		);

		if (!touchInitialCoords?.current) {
			touchInitialCoords.current = {
				x: touch1.clientX,
				y: touch1.clientY
			};
		}

		if (!initialDistance) {
			touchInitialDistance.current = newDistance;
		}

		const { x, y } = touchInitialCoords?.current || {};

		const movementX = touch1.clientX - x;
		const movementY = touch1.clientY - y;

		touchInitialCoords.current = { x: touch1.clientX, y: touch1.clientY };

		/* const distance = newDistance - touchInitialDistance.current; */

		const scale = newDistance / touchInitialDistance.current;

		touchInitialDistance.current = newDistance;

		return {
			movementX,
			movementY,
			// distance,
			centerX,
			centerY,
			touch1,
			touch2,
			scale
		};
	};

	const isDoubleTouchEvent = eventParams => {
		return !!(eventParams?.e.touches?.length === 2);
	};

	return {
		parseData,
		clear,
		isDoubleTouchEvent
	};
}

export function useImageZoomPan({ id, canvas = null, layersToSync }) {
	const dispatch = useDispatch();

	const isPanning = useRef(false);

	const { clear, parseData, isDoubleTouchEvent } = useParseTouchEvents();

	const debouncedUpdatePositions = debounce((payload = {}) => {
		dispatch(setImagePositions(payload));
	}, 200);

	const debouncedUpdateZoom = debounce(zoom => {
		dispatch(setZoom(zoom));
	}, 0);

	const syncronizedAction = (fnName, params = []) => {
		if (!fnName) return;

		canvas?.[fnName]?.(...params);

		if (layersToSync?.length) {
			for (const layer of layersToSync) {
				if (!layer) continue;

				layer?.[fnName]?.(...params);
			}
		}
	};

	const handleMouseDown = params => {
		if (params?.e?.button === 1) {
			isPanning.current = true;
			const canvasImg = extractCanvasImg(canvas);

			if (canvasImg) {
				canvasImg.set({
					hoverCursor: 'grabbing',
					moveCursor: 'grabbing'
				});
			}
		}
	};

	const handleMouseUp = () => {
		clear();
		isPanning.current = false;

		const canvasImg = extractCanvasImg(canvas);

		if (canvasImg) {
			canvasImg.set({
				hoverCursor: 'default',
				moveCursor: 'default'
			});
		}
	};

	const handlePinchToZoom = params => {
		const { movementX, movementY, scale, centerX, centerY } =
			parseData(params);

		const lastZoom = canvas.getZoom();

		const zoomFactor = Math.max(
			MIN_ZOOM,
			Math.min(MAX_ZOOM, lastZoom * scale)
		);

		const { currentLeft, currentTop } = getCurrentPositions(canvas);

		const limits = getLimits(canvas, zoomFactor);

		const { minTop, maxTop, minLeft, maxLeft } = limits;

		const absoluteMovementX = currentLeft + movementX;
		const absoluteMovementY = currentTop + movementY;

		const nextLeft =
			absoluteMovementX > -maxLeft && absoluteMovementX < minLeft
				? movementX
				: 0;

		const nextTop =
			absoluteMovementY > -maxTop && absoluteMovementY < -minTop
				? movementY
				: 0;

		const pointer = new Point(centerX, centerY);

		syncronizedAction('zoomToPoint', [pointer, zoomFactor]);
		syncronizedAction('relativePan', [{ x: nextLeft, y: nextTop }]);

		debouncedUpdateZoom(zoomFactor);
	};

	const handleMouseMove = params => {
		if (isDoubleTouchEvent(params)) {
			return handlePinchToZoom(params);
		}

		if (!isPanning?.current) return;

		const zoom = canvas.getZoom();

		const { movementX, movementY } = params.e || {
			movementX: 0,
			movementY: 0
		};

		const { currentLeft, currentTop } = getCurrentPositions(canvas);

		const { nextLeft, nextTop } = limitNextPositions(
			{ top: currentTop + movementY, left: currentLeft + movementX },
			{ canvas, zoom }
		);

		syncronizedAction('absolutePan', [{ x: -nextLeft, y: -nextTop }]);

		debouncedUpdatePositions({ left: nextLeft, top: nextTop });
	};

	const handleWheel = params => {
		params.e.preventDefault();
		params.e.stopPropagation();
		const { deltaY = 0, deltaX = 0 } = params?.e || {};

		let zoom = canvas.getZoom();

		if (params?.e?.ctrlKey) {
			zoom -= deltaY / 1000;

			zoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, zoom));

			const pointer = canvas.getPointer(params.e);

			dispatch(setZoom(zoom));

			syncronizedAction('zoomToPoint', [pointer, zoom]);

			return;
		}

		const { minTop, maxTop, maxLeft, minLeft } = getLimits(canvas, zoom);

		const { currentTop, currentLeft } = getCurrentPositions(canvas);

		const movementY = deltaY / 10;
		const movementX = deltaX / 10;

		const absoluteMovementY = currentTop - movementY;
		const absoluteMovementX = currentLeft - movementX;

		const nextTop =
			absoluteMovementY > -maxTop && absoluteMovementY < minTop
				? -movementY
				: 0;

		const nextLeft =
			absoluteMovementX > -maxLeft && absoluteMovementX < -minLeft
				? -movementX
				: 0;

		syncronizedAction('relativePan', [{ x: nextLeft, y: nextTop }]);
	};

	const clearEvents = () => {
		if (canvas) {
			canvas.off('mouse:down', handleMouseDown);
			canvas.off('mouse:up', handleMouseUp);
			canvas.off('mouse:move', handleMouseMove);
			canvas.off('mouse:wheel', handleWheel);
		}
	};

	useEffect(() => {
		if (canvas) {
			// window.FabricPoint = Point;
			clearEvents();
			canvas.on('mouse:down', handleMouseDown);
			canvas.on('mouse:up', handleMouseUp);
			canvas.on('mouse:move', handleMouseMove);
			canvas.on('mouse:wheel', handleWheel);
		}

		return () => {
			clearEvents();
		};
	}, [canvas, layersToSync]);

	// Restore position
	useEffect(() => {
		dispatch(restorePositions());

		syncronizedAction('setViewportTransform', [initialViewportTransform]);
	}, [id]);
}
