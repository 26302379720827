import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Dialog,
	DialogContent,
	dialogContentClasses,
	Stack
} from '@mui/material';
import { useLoadCfTurnstileScript } from '@/components/CfCaptchaWidget';
import {
	AUTH_MODAL_TYPES,
	clearAuthModalData,
	setAuthModalType
} from '@/store/auth';
import { useOauthErrors } from '@/hooks/oauth';

import { Login } from './Login';
import { SignUp } from './SignUp';
import { RecoverPassword } from './RecoverPassword';
import { ChangePassword } from './ChangePassword';
import { IconButton } from '../IconButton';
import { CloseIcon } from '../Icons';
import { AUTH_MIN_WIDTH } from './constants';

export function AuthModal() {
	const { clearOauthError } = useOauthErrors();
	const authModalType = useSelector(state => state.auth.authModalType);
	const dispatch = useDispatch();
	const [lastOpen, setLastOpen] = useState(authModalType);
	const type = authModalType || lastOpen;
	const open = !!authModalType;

	useEffect(() => {
		if (authModalType) {
			return setLastOpen(authModalType);
		}
		setTimeout(() => {
			setLastOpen(null);
		}, 200);
	}, [authModalType]);

	useLoadCfTurnstileScript(open);

	useEffect(() => {
		if (!authModalType) {
			setTimeout(() => {
				dispatch(clearAuthModalData());
			}, 200);
		}
	}, [authModalType]);

	const handleClose = (_, reason) => {
		if (reason !== 'backdropClick') {
			dispatch(setAuthModalType(null));
			clearOauthError();
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xl"
			sx={{
				[`.${dialogContentClasses.root}`]: {
					padding: { xs: '40px 24px 0px', sm: '40px 24px 32px' }
				}
			}}
		>
			<IconButton
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: ({ spacing }) => spacing(1.5),
					top: ({ spacing }) => spacing(1.5),
					zIndex: 1
				}}
			>
				<CloseIcon sx={{ color: 'text.secondary' }} />
			</IconButton>
			<Stack
				component={DialogContent}
				direction="row"
				width="100%"
				minWidth={AUTH_MIN_WIDTH}
				alignItems="center"
				textAlign="center"
				sx={{
					px: 3,
					pt: 5,
					pb: 4,
					overflowY: 'visible'
				}}
			>
				<Stack
					spacing={2}
					width={{
						xs: '100%',
						sm: 372
					}}
					mx="auto"
				>
					{type === AUTH_MODAL_TYPES.LOGIN && <Login />}
					{type === AUTH_MODAL_TYPES.SIGN_UP && <SignUp />}
					{type === AUTH_MODAL_TYPES.FAST_SIGN_UP && (
						<SignUp fastCheckout />
					)}
					{type === AUTH_MODAL_TYPES.RECOVER_PASSWORD && (
						<RecoverPassword />
					)}
					{type === AUTH_MODAL_TYPES.CHANGE_PASSWORD && <ChangePassword />}
				</Stack>
			</Stack>
		</Dialog>
	);
}
