import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { Section } from './Section';

function DeleteAccount() {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);
	const { t } = useTranslation();

	const handleDeleteClick = () => {
		setIsConfirmationModalOpen(true);
	};

	return (
		<Section
			title={t('myAccount.deleteAccount.title')}
			subtitle={t('myAccount.deleteAccount.subtitle')}
			action={
				<Button
					color="error"
					onClick={handleDeleteClick}
					variant="contained"
				>
					{t('myAccount.deleteAccount.button')}
				</Button>
			}
		>
			<DeleteConfirmationModal
				onClose={() => setIsConfirmationModalOpen(false)}
				open={isConfirmationModalOpen}
			/>
		</Section>
	);
}

export default DeleteAccount;
